#tumbleweed {
    position: relative;
    animation: tumble 8s linear;
    animation-iteration-count: infinite;
    margin-left: -50%;
}

@keyframes tumble {
    0% {
        left: 0;
        margin-left: -50%;
        transform: rotate(0) translateY(0px);
    }
    25% {
        margin-left: 0;
        transform: rotate(360deg) translateY(-100%);
    }
    50% {
        transform: rotate(720deg) translateY(100%);
    }
    75% {
        transform:  rotate(1080deg) translateY(0%);
    }
    100% {
        left: 100%;
        margin-left: 50%;
        transform: rotate(1440deg) translateY(-100%);
    }
}